@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --ring: 240 3.7% 15.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

::selection {
  background-color: #455CE9;
  color: #fff;
}

html {
  min-width: 360px;
  scroll-behavior: smooth;
}



/* https://seek-oss.github.io/capsize/ */
.capsize::before {
  content: '';
  margin-bottom: -0.098em;
  display: table;
}

.capsize::after {
  content: '';
  margin-top: -0.219em;
  display: table;
}

p {
  @apply text-sm
}

@layer base {
  #__next {
    @apply bg-gray-50 dark:bg-gray-900;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .skip-nav {
    @apply absolute px-4 py-3 transition-transform duration-200 transform -translate-y-12 -left-1/4 focus: top-4 focus:translate-y-3 -top-8;
  }

  #skip {
    scroll-margin-top: 1.125rem;
  }

  @supports not (backdrop-filter: none) {
    .sticky-nav {
      backdrop-filter: none;
      @apply bg-opacity-100;
    }
  }
}